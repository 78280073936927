/* eslint-disable import/prefer-default-export */
import { optionsDashboard } from '@modules/theme/helpers/menuDashboard';
import { optionsOrder } from '@modules/theme/helpers/menuOrder';
import { optionsPickPack } from '@modules/theme/helpers/menuPickPack';
import { optionsShipment } from '@modules/theme/helpers/menuShipment';
import { optionsReturn } from '@modules/theme/helpers/menuReturn';
import { optionsCatalog } from '@modules/theme/helpers/menuCatalog';
import { optionsInventory } from '@modules/theme/helpers/menuInventory';
import { optionsMasters } from '@modules/theme/helpers/menuMasters';
import { optionsMarketplace } from '@modules/theme/helpers/menuMarketplace';
import { optionsVendor } from '@modules/theme/helpers/menuVendor';
import { optionsTada } from '@modules/theme/helpers/menuTada';
import { optionsReport } from '@modules/theme/helpers/menuReport';
import { optionsConfigurations } from '@modules/theme/helpers/menuConfigurations';
import { optionsTools } from '@modules/theme/helpers/menuTools';
import { optionsUser } from '@modules/theme/helpers/menuUser';
import { optionsReplenishment } from '@modules/theme/helpers/menuReplenishment';

export const helpersMenuList = [
    ...optionsDashboard,
    ...optionsOrder,
    ...optionsPickPack,
    ...optionsShipment,
    ...optionsReturn,
    ...optionsCatalog,
    ...optionsInventory,
    ...optionsMasters,
    ...optionsMarketplace,
    ...optionsVendor,
    ...optionsTada,
    ...optionsReport,
    ...optionsConfigurations,
    ...optionsTools,
    ...optionsUser,
    ...optionsReplenishment,
];
