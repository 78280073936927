/* eslint-disable import/prefer-default-export */
export const optionsMasters = [
    {
        aclCode: 'oms_lite_header_oms',
        key: 'oms',
        label: 'Masters',
        children: [
            {
                aclCode: 'oms_lite_company',
                key: 'company',
                label: 'Company',
                url: '/oms/company',
            },
            {
                aclCode: 'oms_lite_channel',
                key: 'channel',
                label: 'Channel',
                url: '/oms/channel',
            },
            {
                aclCode: 'oms_lite_virtual_stock',
                key: 'virtualstock',
                label: 'Virtual Stock',
                url: '/cataloginventory/virtualstock',
            },
            {
                aclCode: 'oms_lite_location',
                key: 'location',
                label: 'Location',
                url: '/oms/location',
            },
            {
                aclCode: 'oms_lite_location_pickup',
                key: 'locationpickup',
                label: 'Location Pickup',
                url: '/oms/locationpickup',
            },
            {
                aclCode: 'oms_lite_virtual_location',
                key: 'virtuallocationinventory',
                label: 'Virtual Location',
                url: '/cataloginventory/virtuallocationinventory',
            },
            {
                aclCode: 'oms_lite_priority_location_by_city',
                key: 'prioritylocation',
                label: 'Priority Location',
                url: '/oms/prioritylocation',
            },
        ],
    },
];
