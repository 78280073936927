/* eslint-disable import/prefer-default-export */
export const optionsMarketplace = [
    {
        aclCode: 'oms_lite_header_marketplace',
        key: 'marketplace',
        label: 'Marketplace',
        children: [
            {
                aclCode: 'oms_lite_marketplace',
                key: 'marketplaces',
                label: 'Marketplace',
                url: '/marketplace/marketplace',
            },
            {
                aclCode: 'oms_lite_marketplace_warehouse',
                key: 'warehouse',
                label: 'Warehouse',
                url: '/marketplace/warehouse',
            },
            {
                aclCode: 'oms_lite_marketplace_product_categories',
                key: 'productcategory',
                label: 'Product Category',
                url: '/marketplace/productcategory',
            },
            {
                aclCode: 'oms_lite_mapping_product_attribute',
                key: 'productattributemapping',
                label: 'Product Attribute Mapping',
                url: '/marketplace/productattributemapping',
            },
            {
                aclCode: 'oms_lite_marketplace_product_status',
                key: 'productstatus',
                label: 'Product Status',
                url: '/marketplace/productstatus',
            },
            {
                aclCode: 'oms_lite_marketplace_product_price',
                key: 'productprice',
                label: 'Product Price',
                url: '/marketplace/productprice',
            },
            {
                aclCode: 'oms_lite_marketplace_product_promo',
                key: 'productpromo',
                label: 'Product Promo',
                url: '/marketplace/productpromo',
            },
            // {
            //     aclCode: 'oms_lite_marketplace_update_stock_history',
            //     key: 'updatestockhistory',
            //     label: 'Update Stock History',
            //     url: '/marketplace/updatestockhistory',
            // },
        ],
    },
];
