/* eslint-disable import/prefer-default-export */
export const optionsCatalog = [
    {
        aclCode: 'header_product',
        key: 'catalog',
        label: 'Catalog',
        children: [
            {
                aclCode: 'oms_lite_product_list',
                key: 'productlist',
                label: 'Products',
                url: '/product/productlist',
            },
            {
                aclCode: 'oms_lite_product_bin',
                key: 'productbin',
                label: 'Product Bin',
                url: '/product/productbin',
            },
            {
                aclCode: 'product_category_by_channel',
                key: 'categorybychannel',
                label: 'Category by Channel',
                url: '/product/categorybychannel',
            },
        ],
    },
];
