/* eslint-disable import/prefer-default-export */
export const optionsConfigurations = [
    {
        aclCode: 'oms_lite_header_configurations',
        key: 'configurations',
        label: 'Configurations',
        children: [
            {
                aclCode: 'oms_lite_config_integrations',
                key: 'integrations',
                label: 'Integrations',
                url: '/configurations/integrations',
            },
            {
                aclCode: 'oms_lite_config_shipments',
                key: 'shipments',
                label: 'Shipments',
                url: '/configurations/shipments',
            },
            {
                aclCode: 'oms_lite_logistix_provider',
                key: 'logistixprovider',
                label: 'Logistix Provider',
                url: '/configurations/logistixprovider',
            },
            {
                aclCode: 'pick_pack_configuration',
                key: 'pickpack',
                label: 'Pick Pack',
                url: '/configurations/pickpack',
            },
            {
                aclCode: 'oms_lite_header_mpadapter',
                key: 'mpadapter',
                label: 'Marketplace Adapater',
                url: '/configurations/mpadapter',
            },
            {
                aclCode: 'oms_lite_header_mpadapter',
                key: 'marketplacebrand',
                label: 'Marketplace Brand',
                url: '/configurations/marketplacebrand',
            },
            {
                aclCode: 'oms_lite_marketplace_feature',
                key: 'marketplacefeature',
                label: 'Marketplace Feature',
                url: '/configurations/marketplacefeature',
            },
            {
                aclCode: 'configuration_region_mapping',
                key: 'regionmapping',
                label: 'Region Mapping',
                url: '/configurations/regionmapping',
            },
        ],
    },
];
