/* eslint-disable import/prefer-default-export */
export const optionsInventory = [
    {
        aclCode: 'oms_lite_header_catalog_inventory',
        key: 'inventory',
        label: 'Inventory',
        children: [
            {
                aclCode: 'oms_lite_source',
                key: 'source',
                label: 'Manage Source',
                url: '/cataloginventory/source',
            },
            {
                aclCode: 'oms_lite_override_stock',
                key: 'overridestock',
                label: 'Override Stock',
                url: '/cataloginventory/overridestock',
            },
            {
                aclCode: 'stock_summary',
                key: 'stocksummary',
                label: 'Stock Summary',
                url: '/cataloginventory/stocksummary',
            },
            {
                aclCode: 'oms_lite_stock_transfer',
                key: 'stocktransfer',
                label: 'Stock Transfer',
                url: '/cataloginventory/stocktransfer',
            },
            {
                aclCode: 'inventory_adjustment_dashboard',
                key: 'stockadjustment',
                label: 'Stock Adjustment',
                url: '/cataloginventory/stockadjustment',
            },
            {
                aclCode: 'oms_lite_location_price_upload',
                key: 'locationpriceupload',
                label: 'Manage Price',
                url: '/cataloginventory/locationpriceupload',
            },
            {
                aclCode: 'oms_lite_tools_history',
                key: 'toolshistory',
                label: 'Update Stock History',
                url: '/tools/history',
            },
        ],
    },
];
