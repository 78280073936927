/* eslint-disable import/prefer-default-export */
export const optionsTools = [
    {
        aclCode: 'oms_lite_header_tools',
        key: 'tools',
        label: 'Tools',
        children: [
            {
                aclCode: 'oms_lite_tools_cli',
                key: 'clitools',
                label: 'CLI Tools',
                url: '/tools/clitools',
            },
        ],
    },
];
