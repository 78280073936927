/* eslint-disable import/prefer-default-export */
export const optionsOrder = [
    {
        aclCode: 'oms_lite_header_sales',
        key: 'order',
        label: 'Order',
        children: [
            {
                aclCode: 'oms_lite_sales_order_queue',
                key: 'orderqueue_all',
                label: 'All Orders',
                url: '/order/allorder',
            },
            {
                aclCode: 'oms_lite_sales_order_queue',
                key: 'orderqueue_allocating',
                label: 'Allocating',
                url: '/order/allocating',
            },
            {
                aclCode: 'oms_lite_sales_order_queue',
                key: 'orderqueue_failed',
                label: 'Failed',
                url: '/order/failed',
            },
            {
                aclCode: 'oms_lite_sales_order_queue',
                key: 'orderqueue_order_processing',
                label: 'Order Processing',
                url: '/order/order_processing',
            },
            {
                aclCode: 'oms_lite_sales_order_queue',
                key: 'orderqueue_shipment_processing',
                label: 'Shipment Processing',
                url: '/order/shipment_processing',
            },
            {
                aclCode: 'oms_lite_sales_order_queue',
                key: 'orderqueue_complete',
                label: 'Complete',
                url: '/order/complete',
            },
            {
                aclCode: 'oms_lite_sales_order_queue',
                key: 'orderqueue_canceled',
                label: 'Canceled',
                url: '/order/canceled',
            },
            {
                aclCode: 'sales_order_queue_bulk_tools',
                key: 'orderqueue_bulk_tools',
                label: 'Bulk Tools',
                url: '/order/orderqueue/bulktools',
            },
            // { aclCode: 'orderreallocation', label: 'Order Reallocation', url: '/sales/orderreallocation' },
        ],
    },
];
