/* eslint-disable import/prefer-default-export */
export const optionsReturn = [
    {
        aclCode: 'header_sales_return',
        key: 'salesreturn',
        label: 'Return',
        children: [
            {
                aclCode: 'oms_lite_credit_memos',
                key: 'creditmemos',
                label: 'Credit Memos',
                url: '/return/creditmemos',
            },
            {
                aclCode: 'oms_lite_rma_manage',
                key: 'managerma',
                label: 'Manage RMA',
                url: '/return/managerma',
            },
            {
                aclCode: 'oms_lite_rma_statuses',
                key: 'rmastatuses',
                label: 'RMA Statuses',
                url: '/return/rmastatuses',
            },
        ],
    },
];
