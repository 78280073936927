/* eslint-disable import/prefer-default-export */
export const optionsUser = [
    {
        aclCode: 'oms_lite_header_user_data',
        key: 'userData',
        label: 'User',
        children: [
            {
                aclCode: 'oms_lite_admin_store',
                key: 'adminstore',
                label: 'All Users',
                url: '/userdata/adminstore',
            },
            {
                aclCode: 'manage_user_group',
                key: 'usergroupacl',
                label: 'User Group',
                url: '/userdata/group',
            },
        ],
    },
];
